import AuthAxios, { backURLs } from "../../../../core/APIs";
import { ElectricityTableItemDto } from "../../../../models/electricity/electricityTableItemDto";
import { ElectricityUpdateItemDto } from "../../../../models/electricity/electricityUpdateItemDto";

const baseUrl = backURLs.electricity;

export const electricityApi = {
  getBalanceElectricityData: (objects: string[], startDt: string, endDt: string, objectsType: string) =>
    AuthAxios.get<ElectricityTableItemDto[]>(`${baseUrl}/table`, {
      params: {
        objects,
        startDt,
        endDt,
        objectsType,
      },
      paramsSerializer: {
        indexes: null,
      },
    }),

  updateBalanceElectricityNorm: (parameters: ElectricityUpdateItemDto) =>
    AuthAxios.put(`${baseUrl}/normative`, null, { params: parameters }),
};

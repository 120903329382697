import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import moment from "moment";
import lineChartDot from "../../../../assets/icons/charts/lineChartsDot.svg";
import lineChartDotExcess from "../../../../assets/icons/charts/lineChartsDotExcess.svg";
import { LoadingState } from "../../../../types/loadingState";
import Loader from "../../../../components/uiComponents/Animation/Animation";

export const MonitoringEventInfoMetersChart = () => {
  const eventInfoTableData = useTypedSelector((state) => state.monitoringEventInfo.eventInfoTableData);
  const isLoadingTableData = useTypedSelector((state) => state.monitoringEventInfo.isLoadingTableData);

  const series = [
    {
      name: "Показание, кгс/м2",
      data: eventInfoTableData.map((i) => [new Date(i.date as string).getTime(), i.value]) as [number, number][],
    },
  ];
  const options: ApexOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 0,
    },
    annotations: {
      points: eventInfoTableData.map((i) => ({
        x: new Date(i.date as string).getTime(),
        y: i.value as number,
        marker: {
          size: 0,
        },
        image: i.anomalySign
          ? {
            path: lineChartDotExcess,
            width: 43,
            height: 43,
            offsetX: 0,
            offsetY: 0,
          }
          : {
            path: lineChartDot,
            width: 12,
            height: 13,
            offsetX: 0,
            offsetY: 0,
          },
      })),
    },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
      labels: {
        formatter: (value: string) => {
          return moment(value).format("DD.MM HH:mm");
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value: number) => {
          return value.toFixed(2);
        },
      },
    },
  };

  if (isLoadingTableData === LoadingState.PENDING) {
    return <Loader />
  }

  return <Chart height={"100%"} width={"100%"} options={options} series={series} type="line" />;
};

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getElectricityTableData, updateElectricityNorm } from "../../../../store/slices/electricity/electricitySlice";
import { GridColDef } from "@mui/x-data-grid";
import { BaseTable } from "../../../../components/baseTable/BaseTable";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { LoadingState } from "../../../../types/loadingState";
import Animation from "../../../../components/uiComponents/Animation/Animation";
import { ElectricityTableItemDto } from "../../../../models/electricity/electricityTableItemDto";
import { setYupLocales } from "../../../../utilities/validation/setYupLocales";
import { ObjectSchema, number, object } from "yup";

interface ElectricityTableProps {
  objects: string[];
  startDt: string;
  endDt: string;
  objectsType: string;
}

setYupLocales();
const validationSchema: ObjectSchema<{ norm: number }> = object({
  norm: number().required().label("Норматив"),
});

export const ElectricityTable = (props: ElectricityTableProps) => {
  const dispatch = useDispatch<any>();

  const data = useTypedSelector((state) => state.electricity.data);
  const isLoading = useTypedSelector((state) => state.electricity.isLoading);

  const columns: GridColDef[] = [
    {
      field: "objectName",
      headerName: "Название объекта",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "month",
      headerName: "Месяц",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        const date = new Date(params.row.dt);
        const year = date.getFullYear();
        return `${params.row.month} ${year}`;
      },
    },
    {
      field: "volume",
      headerName: "Объем воды, м. куб",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "ee",
      headerName: "Электроэнергия, кВт*ч",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "specificConsumption",
      headerName: "Удельное потребление, кВт*ч/м3",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "norm",
      headerName: "Целевое значение, кВт*ч/м3",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      editable: true,
    },
  ];

  const loadTableData = () => {
    dispatch(
      getElectricityTableData({ objects: props.objects, startDt: props.startDt, endDt: props.endDt, objectsType: props.objectsType })
    );
  };

  const handleSave = async (row: ElectricityTableItemDto) => {
    await dispatch(updateElectricityNorm({ objectName: row.objectName, dt: row.dt, value: row.norm }));
    loadTableData();
  };

  useEffect(() => {
    loadTableData();
  }, [props.objects, props.startDt, props.endDt]);

  if (isLoading === LoadingState.PENDING) {
    return <Animation></Animation>;
  }
  return <BaseTable data={data} columns={columns}  onSave={handleSave} validationSchema={validationSchema} />;
};

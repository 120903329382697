import { useEffect } from "react";
import { useState } from "react";
import "./ModalWindowReport.scss";
import "../../../uiComponents/Button/CustomButton";
import CustomButton from "../../../uiComponents/Button/CustomButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DownloadFile, GetTypeReports, GetZonesList, typeReports, ZoneShortInfo } from "../../../../pages/balance/BalansApis";
import Animation from "../../../uiComponents/Animation/Animation";
import { months } from "./constants";

function ModalWindowReport() {
  const [load, setLoad] = useState(false)
  const [type, setType] = useState("Analytics1")
  const [reports, setReports] = useState<typeReports[]>([])
  const [selectedMonth, setSelectedMonth] = useState<number>();
  const [selectedZone, setSelectedZone] = useState<number>();
  const [selectedYear, setSelectedYear] = useState<number>();
  const [zones, setZones] = useState<ZoneShortInfo[]>()

  async function getTypeReports() {
    try {
      const response = await GetTypeReports()
      setReports(response)
      setType(response[0].value)
    }
    catch (error) {
      console.log(error);
    }
  }


  async function getZones() {
    try {
      const response = await GetZonesList()
      setZones(response)

    }
    catch (error) {
      console.log(error);
    }
  }

  async function downloadFile() {
    try {
      setLoad(true);
      const response = await DownloadFile(type, selectedMonth, selectedYear, selectedZone);
      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "report.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
  }

  useEffect(() => {
    getTypeReports()
    getZones()
  }, [])

  return (
    <div className="conteiner">
      <div className="titleReport">
        <span className="headerH1">Фильтр отчета</span>
      </div>
      <div className="bodyReport">

        {
          load ? <Animation></Animation> :
            <>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Тип отчета</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Тип отчета"
                  placeholder="Тип отчета"
                  onChange={(e) => setType(e.target.value)}
                >
                  {reports.map((item) => {
                    return (
                      <MenuItem value={item.value}>{item.displayName}</MenuItem>
                    )
                  })}

                </Select>
              </FormControl>
              {type === 'ReportNine' && <> <FormControl fullWidth size="small">
                <InputLabel id="zonaSelectLabel">Зона</InputLabel>
                <Select
                  labelId="zonaSelectLabel"
                  id="zonaSelect"
                  value={selectedZone}
                  label="Зона"
                  onChange={(e) => setSelectedZone(e.target.value as number)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  {zones?.map((zone) => (
                    <MenuItem key={zone.id} value={zone.id}>
                      {zone.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
                <FormControl fullWidth size="small">
                  <InputLabel id="yearSelectLabel">Год</InputLabel>
                  <Select
                    labelId="yearSelectLabel"
                    id="yearSelect"
                    value={selectedYear}
                    label="Год"
                    onChange={(e) => setSelectedYear(e.target.value as number)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                      },
                    }}
                  >
                    {Array.from({ length: new Date().getFullYear() - 2021 }, (_, i) => 2022 + i).map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small">
                  <InputLabel id="mounthSelectLabel" >Месяц</InputLabel>
                  <Select
                    labelId="mounthSelectLabel"
                    id="mounthSelect"
                    value={selectedMonth}
                    label="Месяц"
                    onChange={(e) => setSelectedMonth(e.target.value as number)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                      },
                    }}
                  >
                    {months.map((month) => (
                      <MenuItem key={month.value} value={month.value}>
                        {month.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>}
            </>
        }
        {load ?
          <div style={{ width: "100%" }}>
            <div className="chartScales" style={{ color: "red", width: "100%", textAlign: "center" }}>Происходит скачивание файла</div>
            <CustomButton style={{ width: "100%", height: "40px" }} disabled={true} onClick={() => {
              downloadFile();
            }}>СКАЧАТЬ ДОКУМЕНТ</CustomButton>
          </div> : <CustomButton style={{ width: "100%", height: "40px" }} onClick={() => {
            downloadFile();
          }}>СКАЧАТЬ ДОКУМЕНТ</CustomButton>}


        <CustomButton style={{ width: "100%", height: "40px" }}>ОТМЕНА</CustomButton>
      </div>
    </div >
  );
}

export default ModalWindowReport;

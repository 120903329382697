import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { DataGrid, GridColDef, ruRU } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import moment, { Moment } from "moment";
import BlockInfo from "../../blockInfo/BlockInfo";
import {
  GetBalanceGroupSupply,
  GetSupplyBGCard,
  BGSupplyTable,
  GetBGSupplyTable,
  BlocksInfoCard,
} from "../../BalansApis";
import Animation from "../../../../components/uiComponents/Animation/Animation";
import { ApexLocales } from "../../../../constants/ApexChartsSettings";
import { useMatch } from "react-router-dom";
import { routeUrls } from "../../../../routes/routeUrls";
import { CustomDateTimePicker } from "../../../../components/formFields/customDateTimePicker.tsx/customDateTimePicker";
import { getFormattedDate } from "../../../../utilities/dateHelper";
import ApexCharts from "apexcharts";

//первый день года
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const firstDayOfYear = new Date(currentYear, 0, 1);
//последний день года
const lastDayOfYear = new Date(currentYear, 11, 31);

interface SupplyInterface {
  bgNumber: number;
}

const Supply: React.FC<SupplyInterface> = ({ bgNumber }) => {
  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: "Дата",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "released",
      headerName: "Подано в группу, тыс. м.куб",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "givenAway",
      headerName: "Подано абонентам, тыс. м.куб",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
  ];

  // Переменные начала и конца периода соответствено
  const [startDate, setStartDate] = useState<Moment>(moment(firstDayOfYear));
  const [endDate, setEndDate] = useState<Moment>(moment());

  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [supplyData, setSupplyData] = useState<BlocksInfoCard>({
    header: "",
    blocks: [],
    name: "",
  });

  const [chartData, setChartData] = useState<ApexOptions>({
    series: [],
    chart: {
      id: "mainChart",
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
      events: {
        mounted: (_, options) => {
          options?.config?.series?.forEach((data: { name: string }) => {
            if (data.name !== new Date().getFullYear().toString()) {
              ApexCharts.exec("mainChart", "hideSeries", data.name);
            }
          });
        },
      },
    },

    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    legend: {
      show: true,
    },
    labels: [],
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: `тыс. м. куб`,
      },
    },
  });

  const [bGSupplyTable, setBGSupplyTable] = useState<BGSupplyTable[]>([]);
  const [isLoadingTable, setIsLoadingTable] = useState(false);

  const routeMatch = useMatch({ path: routeUrls.bg });
  const zoneNumber = routeMatch?.params.zone;

  async function getChartData(startDate: Moment, endDate: Moment) {
    if (bgNumber < 1000 || zoneNumber === undefined) return 0;
    setIsLoading(false);
    try {
      const response = await GetBalanceGroupSupply(
        getFormattedDate(startDate),
        getFormattedDate(endDate),
        zoneNumber,
        bgNumber
      );
      setChartData({ ...chartData, series: response.series, labels: response.dates });
      setIsLoading(true);
    } catch (e) {
      console.log(e);
    }
  }

  // Получение данных для карточек в БГ.Подача
  async function getSupplyBGCard(startDate: Moment, endDate: Moment) {
    if (bgNumber < 1000 || zoneNumber === undefined) return 0;
    setIsLoadingCard(false);
    try {
      const response = await GetSupplyBGCard(
        getFormattedDate(startDate),
        getFormattedDate(endDate),
        zoneNumber,
        bgNumber
      );
      if (response !== undefined) setSupplyData(response);
      setIsLoadingCard(true);
    } catch (e) {
      console.log(e);
    }
  }

  //Запрос для таблицы (БГ.Подача.Таблица)
  async function getBGSupplyTable(startDate: Moment, endDate: Moment) {
    if (bgNumber < 1000 || zoneNumber === undefined) return 0;
    setIsLoadingTable(false);
    try {
      const response = await GetBGSupplyTable(
        zoneNumber,
        bgNumber,
        getFormattedDate(startDate),
        getFormattedDate(endDate)
      );
      setBGSupplyTable(response);
      setIsLoadingTable(true);
    } catch (e) {
      console.log(e);
    }
  }

  const handleStartDateChange = (value: Moment | null) => {
    if (value) {
      setIsLoading(false);
      setStartDate(value);
      getChartData(value, endDate);
      getBGSupplyTable(value, endDate);
      getSupplyBGCard(value, endDate);
    }
  };

  const handleEndDateChange = (value: Moment | null) => {
    if (value) {
      setIsLoading(false);
      getBGSupplyTable(startDate, value);
      setEndDate(value);
      getChartData(startDate, value);
      getSupplyBGCard(startDate, value);
    }
  };

  useEffect(() => {
    getBGSupplyTable(startDate, endDate);
    getChartData(startDate, endDate);
    getSupplyBGCard(startDate, endDate);
  }, [bgNumber]);

  return (
    <div className="balanceTabDefault">
      <div className="BalanceMainForm">
        <div style={{ width: "100%" }}>
          {/* Верхняя легенда */}
          <div className="leftLegendaDate">
            <div style={{ whiteSpace: "nowrap" }} className="headerH2">
              Подача за период с
            </div>
            <CustomDateTimePicker
              dateValue={startDate}
              onDateValueChange={handleStartDateChange}
              minDateTime={moment(firstDayOfYear)}
              maxDateTime={moment(lastDayOfYear)}
            />
            <div style={{ whiteSpace: "nowrap" }} className="headerH2">
              по
            </div>
            <CustomDateTimePicker
              dateValue={endDate}
              onDateValueChange={handleEndDateChange}
              minDateTime={moment(firstDayOfYear)}
              maxDateTime={moment(lastDayOfYear)}
            />
          </div>

          <div style={{ height: "357px", width: "100%" }}>
            {!isLoading ? (
              <Animation></Animation>
            ) : (
              <Chart height={"100%"} width={"100%"} options={chartData} series={chartData.series} type="line" />
            )}
          </div>
        </div>
        {/* Блок описания */}
        <div className="description">
          {!isLoadingCard ? (
            <Animation></Animation>
          ) : (
            supplyData !== undefined && (
              <BlockInfo
                style={{ height: "100%", justifyContent: "center" }}
                header={supplyData.header}
                blocks={supplyData.blocks}
              />
            )
          )}
        </div>
      </div>
      <Box sx={{ width: "100%" }} className="supplyTable tableHeight">
        {!isLoadingTable ? (
          <Animation></Animation>
        ) : (
          <DataGrid
            rows={bGSupplyTable}
            columns={columns}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
      </Box>
    </div>
  );
};

export default Supply;

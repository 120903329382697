import { Fragment, useEffect } from "react";
import { Map as YandexMap, Polygon, Placemark, useYMaps, YMaps, Circle, Polyline } from "@pbe/react-yandex-maps";
import { useDispatch } from "react-redux";
import iconObjectService from "../../assets/icons/map/objectService.svg";
import iconObjectCritical from "../../assets/icons/map/objectCritical.svg";
import iconObjectExcess from "../../assets/icons/map/objectExcess.svg";
import { useNavigate } from "react-router-dom";
import { YandexMapsSettings } from "../../constants/yandexMapsSettings";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { YandexMapsDefaultState } from "../../constants/yandexMapDefaultState";
import { MapObjectDto } from "../../models/map/mapObjectDto";
import { MapZoneDto } from "../../models/mapZones/mapZone";
import { getZones, getMapObjects, getCIMModel, setShowCimModel } from "../../store/slices/monitoringMapSlice";
import "./yandexMap.css";
import { Box, FormControlLabel, Switch } from "@mui/material";
import Loader from "../../components/uiComponents/Animation/Animation";

const cimModelColor = "#4BB8A9";

export const MonitoringMap = () => {
  return (
    <YMaps enterprise query={YandexMapsSettings} version={"2.1.79"}>
      <Map />
    </YMaps>
  );
};

const Map = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const ymaps = useYMaps(["util.bounds"]);

  const mapZones = useTypedSelector((state) => state.monitoringMap.zones);
  const mapObjects = useTypedSelector((state) => state.monitoringMap.mapObjects);
  const cimModels = useTypedSelector((state) => state.monitoringMap.cimModels);
  const showCimModel = useTypedSelector((state) => state.monitoringMap.showCimModel);
  const isCimModelLoading = useTypedSelector((state) => state.monitoringMap.isCimModelLoading);

  let center;
  if (ymaps && mapObjects.length > 0) {
    const points = mapObjects.map((x) => [x.latitude, x.longitude]).sort((first, second) => first[1] - second[1]);
    center = points.length > 1 ? ymaps.util.bounds.getCenter(points) : points[0];
  }

  const mapState = YandexMapsDefaultState;
  mapState.center = center ? [center[0], center[1]] : mapState.center;

  const handlePlaceMarkClick = async (object: MapObjectDto) => {
    navigate(`/objectInfo/${object.id}`);
  };

  const getIconHrefByEventType = (eventType: number) => {
    switch (eventType) {
      case 0:
        return iconObjectCritical;
      case 1:
        return iconObjectExcess;
      case 2:
        return iconObjectService;
    }
  };

  const handleShowCimModel = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setShowCimModel(event.target.checked));
  };

  const zonePolygons = mapZones.map((zoneItem: MapZoneDto) => {
    return (
      <Polygon
        key={zoneItem.guid}
        geometry={[zoneItem.coordinates]}
        options={{
          fillColor: zoneItem.color,
          strokeColor: "#000",
          opacity: 0.5,
          strokeWidth: 3,
          strokeStyle: "solid",
        }}
        properties={{
          // Определяем тексты балуна и хинта
          balloonContent: `
        <div class="zoneBalloon">
          <span class="headerH2">${zoneItem.zoneName}</span>
          <hr style="margin: 15px 0">
          <span class="headerH2">Зона ${zoneItem.zone}</span>
        </div>
      `,
          hintContent: `Зона ${zoneItem.zone}`,
        }}
      />
    );
  });

  const objectPlacemarks = mapObjects.map((mapObject) => {
    return (
      <Placemark
        key={mapObject.id}
        instanceRef={(ref: any) => {
          if (ref !== null) {
            ref.events.add("click", () => {
              handlePlaceMarkClick(mapObject);
            });
          }
        }}
        geometry={[mapObject.latitude, mapObject.longitude]}
        options={{
          iconLayout: "default#image",
          iconImageHref: getIconHrefByEventType(mapObject.type),
          iconImageSize: [26, 21],
          iconImageOffset: [-13, -10],
        }}
      />
    );
  });

  const cimModelEdges = cimModels
    .map((x) => x.cimModel.lines)
    .flat()
    .map((edge, index) => {
      if (edge.coordinates && edge.coordinates.length >= 2) {
        return (
          <Polyline
            key={`line-${index}`}
            instanceRef={(ref: any) => {
              if (ref !== null) {
                ref.events.add("click", () => {
                  console.log("Line ->");
                  console.log({ ...edge });
                });
              }
            }}
            geometry={edge.coordinates.map((x) => [x.latitude, x.longitude])}
            options={{
              strokeColor: "#4BB8A9",
              strokeWidth: 2,
            }}
          />
        );
      }
    });

  const cimModelPoint = cimModels
    .map((x) => x.cimModel.points)
    .flat()
    .map((point, index) => {
      const coordinate = point.coordinate;
      return (
        <Circle
          key={`point-${index}`}
          instanceRef={(ref: any) => {
            if (ref !== null) {
              ref.events.add("click", () => {
                console.log("Point ->");
                console.log({ ...point });
              });
            }
          }}
          geometry={[[coordinate.latitude, coordinate.longitude], 0]}
          options={{
            fillColor: cimModelColor,
            strokeColor: cimModelColor,
            strokeWidth: 10,
          }}
        />
      );
    });

  useEffect(() => {
    dispatch(getZones());
    dispatch(getMapObjects());
  }, []);

  useEffect(() => {
    if (showCimModel && cimModels.length === 0) {
      dispatch(getCIMModel({ objectGuids: ["2e4d8d1d-0d06-4245-b053-43b0591967f4"] }));
    }
  }, [showCimModel]);

  return (
    <Box sx={styles.mapContainer}>
      <Box sx={styles.mapBox}>
        {isCimModelLoading && <Loader />}
        {!isCimModelLoading && (
          <YandexMap
            defaultState={YandexMapsDefaultState}
            width="100%"
            height="100%"
            modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
          >
            {zonePolygons}
            {objectPlacemarks}

            {!isCimModelLoading && showCimModel && (
              <>
                {cimModelEdges}
                {cimModelPoint}
              </>
            )}
          </YandexMap>
        )}
      </Box>
      <Box sx={styles.switchBox}>
        <FormControlLabel
          style={{ margin: 0 }}
          control={<Switch checked={showCimModel} onChange={handleShowCimModel} />}
          label={
            <div className="usualText" style={{ whiteSpace: "nowrap" }}>
              Отображать CIM-модель
            </div>
          }
        />
      </Box>
    </Box>
  );
};

const styles = {
  mapContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    gap: "20px",
  },
  mapBox: {
    flex: "1",
    borderRadius: "16px",
    overflow: "hidden",
  },
  switchBox: {},
};

import { AxiosResponse } from "axios";
import AuthAxios, { backURLs, gatewayBaseUrl, gatewayBaseUrlApiV2 } from "../../../core/APIs";
import { EventInfoTableDTO } from "../../../models/eventInfo/EventInfoTableDTO";
import { EventsDataDto, EventsDataPageDto } from "../../../models/events/eventsData";
import { EventStatisticCardDto } from "../../../models/eventStatisticCard/eventStatisticCardDto";
import { EventKeyDto } from "../../../models/events/eventKeyDto";
import { DatePeriodDto } from "../../../models/common/datePeriodDto";
import moment from "moment";

const eventsBaseUrl = backURLs.eventList;
const baseUrl = gatewayBaseUrl;

export const monitoringEventApi = {
  getEventList(pageNum: number, pageSize: number): Promise<AxiosResponse<EventsDataDto>> {
    return AuthAxios.get(`${eventsBaseUrl}/anomalies`, { params: { pageNum, pageSize } });
  },

  getEventsDataBySignalEventGuid(
    signalEventGuid: string,
    pageNum: number,
    pageSize: number
  ): Promise<AxiosResponse<EventsDataPageDto>> {
    return AuthAxios.get(`${eventsBaseUrl}/${signalEventGuid}/${pageNum}/${pageSize}`);
  },

  sendEvent(key: EventKeyDto): Promise<AxiosResponse> {
    return AuthAxios.post(`${baseUrl}/event-value/send`, { key });
  },

  acceptEvent(key: EventKeyDto): Promise<AxiosResponse> {
    return AuthAxios.post(`${baseUrl}/event-value/accept`, { key });
  },

  getEventsStatistics(): Promise<AxiosResponse<EventStatisticCardDto[]>> {
    return AuthAxios.get(`${backURLs.objectInfo}/card-statistics`);
  },
  getTableEventValues(
    signalEventGuid: string,
    period: DatePeriodDto,
    isFiltered: boolean
  ): Promise<AxiosResponse<EventInfoTableDTO[]>> {
    return AuthAxios.get(`${backURLs.objectInfo}/table-event-values/${signalEventGuid}`, {
      params: { ...period, isFiltered },
    });
  },
};

import { ApexOptions } from "apexcharts";
import { ApexLocales } from "../../../constants/ApexChartsSettings";

export const lineGraphData: ApexOptions = {
  series: [
    {
      name: "Подача",
      data: [],
      color: "#777777",
    },
    {
      name: "Сбыт",
      data: [],
      color: "#4BB8A9",
    },
    {
      name: "Потери",
      data: [],
      color: "#1599D7",
    },
  ],
  chart: {
    // width: "100%",
    // height:"100%",
    animations: {
      enabled: false,
    },
    width: "100%",
    type: "line",
    zoom: {
      enabled: false,
    },
    defaultLocale: ApexLocales.defaultLocale,
    locales: ApexLocales.locales,
  },

  dataLabels: {
    enabled: true,
  },
  stroke: {
    curve: "straight",
    width: 2,
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  legend: {
    show: true,
  },
  xaxis: {
    categories: ["Янв", "Фев", "Мар", "Aпр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
  },
  yaxis: {
    title: {
      text: `тыс. м. куб`,
    },
  },
};

export const barOptData: ApexOptions = {
  series: [
    {
      name: "потери, %",
      data: [],
    },
  ],
  chart: {
    type: "bar",
    width: "100%",
    animations: {
      enabled: false,
    },
    defaultLocale: ApexLocales.defaultLocale,
    locales: ApexLocales.locales,
  },
  stroke: {
    curve: "straight",
    width: 2,
  },
  fill: {
    colors: ["#1599D7"],
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      borderRadius: 2,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  xaxis: {
    categories: [],
  },
};

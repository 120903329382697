import axios from "axios";

const AuthAxios = axios.create();

// errorComposer will compose a handleGlobally function
const errorComposer = (error: any) => {
  return () => {
    const statusCode = error.response ? error.response.status : null;

    if (statusCode === 401) {
      localStorage.removeItem("accessToken");
      window.location.assign("/");
    }
  };
};

AuthAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    error.handleGlobally = errorComposer(error);
    throw error;
  }
);

export default AuthAxios;

// Основной микросервис
export const gatewayBaseUrl = `${process.env.REACT_APP_API_URL}/api/waterchannel-gateway/v1`;
export const gatewayBaseUrlApiV2 = `${process.env.REACT_APP_API_URL}/api/waterchannel-gateway/v2`;
const gatewayBalanceBaseUrl = `${process.env.REACT_APP_API_URL}/api/waterchannel-dataanalysis/v1`;
const accessBaseUrl = `${process.env.REACT_APP_API_URL}/api/waterchannel-access/v1`;
const predictionServiceBaseUrl = `${process.env.REACT_APP_PREDICTION_SERVICE_API_URL}/api/predictionservice/v1.0`;
export const anomaliesBaseUrl = `${process.env.REACT_APP_PREDICTION_SERVICE_API_URL}/api/anomalyservice/v1.0`;

// Ссылки на бэк
export const backURLs = {
  account: `${accessBaseUrl}/account`,
  summary: `${gatewayBaseUrl}/summary`,
  waterSupplySummary: `${gatewayBaseUrlApiV2}/summary`,
  relationsZulu: `${gatewayBaseUrl}/relations-zulu`,
  levels: `${gatewayBaseUrl}/levels`,
  counterContracts: `${gatewayBaseUrl}`,
  balances: `${gatewayBalanceBaseUrl}`,
  supply: `${gatewayBaseUrl}/water-supply`,
  consumption: `${gatewayBaseUrl}/water-consumption-data`,
  loss: `${gatewayBalanceBaseUrl}/zone-losses`,
  consumptionZone: `${gatewayBaseUrl}/account-statistics`,
  zoneWaterSales: `${gatewayBaseUrl}/zone-water-sales`,
  balansGroup: `${gatewayBaseUrl}/balance-groups`,
  zones: `${gatewayBaseUrl}/zones`,
  sewerage: `${gatewayBaseUrl}/sewerage`,
  reports: `${gatewayBaseUrl}/reports`,
  electricity: `${gatewayBaseUrl}/electricity`,
  roles: `${accessBaseUrl}/roles`,
  planLoss: `${gatewayBaseUrl}/plan-losses`,
  getSettingsTableUrl: (tableName: string) => `${gatewayBaseUrl}/${tableName}`,
  passportTable: `${gatewayBaseUrl}/passports-table`,
  predictionServiceBaseUrl: predictionServiceBaseUrl,
  eventList: `${gatewayBaseUrl}/event-list`,
  objectInfo: `${gatewayBaseUrl}/object-info`,
  devices: `${gatewayBaseUrl}/device`,
  anomaliesServiceBaseUrl: anomaliesBaseUrl,
  deviceEvents: `${gatewayBaseUrl}/device-events`,
  objectEvents: `${gatewayBaseUrl}/object-events`,
  objectEventsInfo: `${gatewayBaseUrl}/object-events-info`,
  objects: `${gatewayBaseUrl}/objects`,
  anomalies: `${gatewayBaseUrl}/anomaly-events`,
  cimModel: `${gatewayBaseUrl}/cim-model`,
  anomalyData: `${gatewayBaseUrl}/anomalies`,
  referenceConsumer: `${gatewayBaseUrl}/reference-consumer`,
};

export const months = [
  { name: "Январь", value: 1 },
  { name: "Февраль", value: 2 },
  { name: "Март", value: 3 },
  { name: "Апрель", value: 4 },
  { name: "Май", value: 5 },
  { name: "Июнь", value: 6 },
  { name: "Июль", value: 7 },
  { name: "Август", value: 8 },
  { name: "Сентябрь", value: 9 },
  { name: "Октябрь", value: 10 },
  { name: "Ноябрь", value: 11 },
  { name: "Декабрь", value: 12 },
];

export const zones = [
  { name: "I", value: 1 },
  { name: "II", value: 2 },
  { name: "III", value: 3 },
  { name: "IV", value: 4 },
  { name: "V", value: 5 },
  { name: "VI", value: 6 },
  { name: "VII", value: 7 },
  { name: "VIII", value: 8 },
  { name: "IX", value: 9 },
  { name: "X", value: 10 },
  { name: "XI", value: 11 },
  { name: "XII", value: 12 },
  { name: "XIII", value: 13 },
  { name: "XIV", value: 14 },
  { name: "XV", value: 15 },
  { name: "XVI", value: 16 },
  { name: "XVII", value: 17 },
  { name: "XVIII", value: 18 },
  { name: "XIX", value: 19 },
  { name: "XX", value: 20 },
  { name: "XXI", value: 21 },
  { name: "XXII", value: 22 },
  { name: "XXIII", value: 23 },
];
